/**
 * ------------------------------------------------------------------------------
 * Common config
 * ------------------------------------------------------------------------------
 */

export const DURATION = 1;
export const STAGGER = DURATION * 0.1;

export const TRANSLATE_LG = "100%";
export const TRANSLATE_MD = "6rem";
export const TRANSLATE_SM = "1.5rem";
