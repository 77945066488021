/**
 * ------------------------------------------------------------------------------
 * Abstraction for getting translation keyframes
 * ------------------------------------------------------------------------------
 */
import { getTranslation } from "./_getTranslation";

import type {
  AnimationDirection,
  AnimationOrientation,
  AnimationScale,
  ElementAnimationDirection,
} from "../types";

interface GetTranslationKeyframesArgs {
  animationDirection: AnimationDirection;
  elementAnimationDirection: ElementAnimationDirection;
  scale?: AnimationScale;
  orientation?: AnimationOrientation;
}

export const getTranslationKeyframes = ({
  animationDirection,
  elementAnimationDirection,
  scale = "md",
  orientation = "vertical",
}: GetTranslationKeyframesArgs) => {
  if (elementAnimationDirection === "in") {
    return [
      getTranslation({
        orientation,
        scale,
        isNegative: animationDirection === "backward",
      }),
      "none",
    ];
  }

  return [
    "none",
    getTranslation({
      orientation,
      scale,
      isNegative: animationDirection === "forward",
    }),
  ];
};
