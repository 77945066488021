import { useEffect, useRef } from "react";

import { animateElementGroups } from "./support/_animateElementGroups";
import { animateProgressIndicator } from "./support/_animateProgressIndicator";
import { getAllMotionGroups } from "./support/_getAllMotionGroups";
import { getAnimationDirection } from "./support/_getAnimationDirection";

import type { AnimationOrientation } from "./types";
import type { StepType } from "~components/Stepper/types";

interface UseDirectionAwareTransitionsArgs {
  current?: number;
  previous?: number;
  orientation?: AnimationOrientation;
  refContainer?: React.RefObject<HTMLDivElement>;
  stepsType?: Array<StepType>;
}

export const useDirectionAwareTransitions = ({
  current = 0,
  previous = 0,
  orientation = "vertical",
  refContainer,
  stepsType,
}: UseDirectionAwareTransitionsArgs) => {
  const motionGroupsRef = useRef<ReturnType<typeof getAllMotionGroups>>();

  useEffect(() => {
    if (!refContainer?.current || typeof window === "undefined") return;

    /** Check for and update cached value for motion groups */

    if (
      !motionGroupsRef.current ||
      Object.keys(motionGroupsRef?.current).length < 1
    ) {
      motionGroupsRef.current = getAllMotionGroups(refContainer);
    }
    if (!motionGroupsRef.current) return;

    const animationDirection = getAnimationDirection(current, previous);

    animateProgressIndicator(refContainer);

    /**
     * Animate all "primary" elements
     */
    animateElementGroups({
      motionGroups: motionGroupsRef.current,
      current,
      animationDirection,
      previous,
      orientation,
      level: "primary",
      ref: refContainer,
      stepsType,
    });

    /**
     * Animate all "secondary" elements
     */
    animateElementGroups({
      motionGroups: motionGroupsRef.current,
      current,
      animationDirection,
      previous,
      orientation,
      level: "secondary",
      ref: refContainer,
      stepsType,
    });

    /**
     * Animate all "overlay" elements
     */
    animateElementGroups({
      motionGroups: motionGroupsRef.current,
      current,
      animationDirection,
      previous,
      orientation: "vertical",
      level: "overlay",
      ref: refContainer,
      stepsType,
    });
  }, [current, previous, orientation, refContainer, stepsType]);
};
