/**
 * ------------------------------------------------------------------------------
 * Abstraction for getting the translate Y values for an animated element
 * ------------------------------------------------------------------------------
 */
import { calc } from "@vanilla-extract/css-utils";

import { TRANSLATE_LG, TRANSLATE_MD, TRANSLATE_SM } from "../config";

import type { AnimationOrientation, AnimationScale } from "../types";

type Translation = `translate${"Y" | "X"}(${string})` | "none";

interface GetDirectionAwareTranslateYArgs {
  orientation?: AnimationOrientation;
  scale?: AnimationScale;
  isNegative?: boolean;
}

export const getTranslation = ({
  isNegative,
  orientation = "vertical",
  scale = "md",
}: GetDirectionAwareTranslateYArgs): Translation => {
  const translateOrientation =
    orientation === "vertical" ? "translateY" : "translateX";

  if (orientation === "none") {
    return "none";
  }

  // const translationAmount = scale === "md" ? TRANSLATE_LG : TRANSLATE_SM;
  const translationAmount = {
    md: TRANSLATE_MD,
    sm: TRANSLATE_SM,
    lg: TRANSLATE_LG,
  };

  if (isNegative) {
    return `${translateOrientation}(${calc.multiply(
      translationAmount[scale],
      -1
    )})`;
  }

  return `${translateOrientation}(${translationAmount[scale]})`;
};
