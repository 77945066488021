/**
 * ------------------------------------------------------------------------------
 * Abstraction for animating elements within a group
 * ------------------------------------------------------------------------------
 */
import { animate, spring, stagger } from "motion";

import { getMotionSelector } from "~utils/motion_one/motion_attribute_selectors";

import { STEP_TYPES } from "~components/Stepper/types";

import { MOTION_SPRING_LONG, MOTION_SPRING_SHORT } from "~config/SPRING_CONFIG";

import { STAGGER } from "../config";
import { getTranslationKeyframes } from "./_getTranslationKeyframes";

import type {
  AnimationDirection,
  AnimationOrientation,
  AnimationScale,
  ElementAnimationDirection,
} from "../types";
import type { StepType } from "~components/Stepper/types";
import type { MotionAttrEnum } from "~utils/motion_one/motion_attribute_selectors";

interface AnimateElementsInGroupArgs {
  elementAnimationDirection: ElementAnimationDirection;
  animationDirection: AnimationDirection;
  elementMotionAttr: Extract<
    MotionAttrEnum,
    "primary" | "secondary" | "overlay"
  >;
  group?: Element;
  orientation: AnimationOrientation;
  scale: AnimationScale;
  callbackOnComplete?: () => void;
  stepType?: StepType;
}

export const animateElementsInGroup = async ({
  elementAnimationDirection,
  animationDirection,
  elementMotionAttr,
  group,
  orientation,
  scale,
  stepType = "screen",
}: AnimateElementsInGroupArgs) => {
  if (!group) return;

  /** Animated elements will be grouped a `data-motion=group$1` attribute  */

  const currentElements = group.querySelectorAll(
    getMotionSelector(elementMotionAttr)
  );

  if (!currentElements || currentElements?.length < 1) return;

  const opacityKeyframes = {
    in: {
      [STEP_TYPES.SCREEN]: [0, 1],
      [STEP_TYPES.DIALOG]: [1, 1],
    },
    out: {
      [STEP_TYPES.SCREEN]: [1, 0],
      [STEP_TYPES.DIALOG]: [0, 0],
    },
    none: {
      // TODO
      [STEP_TYPES.SCREEN]: "none",
      [STEP_TYPES.DIALOG]: "none",
    },
  };

  const zIndexKeyframes = {
    in: [1, 1],
    out: [0, 0],
    none: "none",
  };

  animate(
    currentElements,
    {
      opacity: opacityKeyframes[elementAnimationDirection][stepType], // elementAnimationDirection === "in" ? [0, 1] : [1, 0],
      zIndex: zIndexKeyframes[elementAnimationDirection],
      // TODO implement new animations
      transform: getTranslationKeyframes({
        animationDirection:
          stepType === STEP_TYPES.DIALOG ? "forward" : animationDirection,
        elementAnimationDirection,
        scale,
        orientation,
      }),
    },
    {
      delay: stagger(STAGGER, { easing: "ease-in" }),
      easing: spring(scale === "md" ? MOTION_SPRING_LONG : MOTION_SPRING_SHORT),
    }
  );
};
