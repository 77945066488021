/**
 * ------------------------------------------------------------------------------
 * Abstraction for getting motion attributes for primary/secondary animations by level
 * ------------------------------------------------------------------------------
 */
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

export const getMotionAttrsByLevel = (
  level: "primary" | "secondary" | "overlay"
) => {
  let groupMotionAttr;
  let elementMotionAttr;

  switch (level) {
    case "primary":
      groupMotionAttr = ANIMATED.groupPrimary;
      elementMotionAttr = ANIMATED.primary;
      break;
    case "overlay":
      groupMotionAttr = ANIMATED.groupOverlay;
      elementMotionAttr = ANIMATED.overlay;
      break;
    case "secondary":
    default:
      groupMotionAttr = ANIMATED.groupSecondary;
      elementMotionAttr = ANIMATED.secondary;
      break;
  }

  return {
    groupMotionAttr,
    elementMotionAttr,
  };
};
