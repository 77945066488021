/**
 * ------------------------------------------------------------------------------
 * Abstraction for animating primary elements
 * ------------------------------------------------------------------------------
 */
import { STEP_TYPES } from "~components/Stepper/types";

import { animateElementsInGroup } from "./_animateElementsWithinGroup";
import { getMotionAttrsByLevel } from "./_getMotionAttributesByLevel";

import type {
  AnimationDirection,
  AnimationLevel,
  AnimationOrientation,
  AnimationScale,
  MotionGroupsDict,
} from "../types";
import type { RefObject } from "react";
import type { StepType } from "~components/Stepper/types";

interface AnimatePrimaryElementsArgs {
  ref: RefObject<HTMLDivElement>;
  orientation: AnimationOrientation;
  animationDirection: AnimationDirection;
  motionGroups: MotionGroupsDict;
  current: number | null;
  previous: number | null;
  level: AnimationLevel; // test
  stepsType?: Array<StepType>; // test
}

export const animateElementGroups = ({
  animationDirection,
  motionGroups,
  current,
  level,
  orientation,
  previous,
  stepsType,
}: AnimatePrimaryElementsArgs) => {
  /** Get 'data-motion' attributes  we'll use to select elements to animate */
  const { elementMotionAttr } = getMotionAttrsByLevel(level);

  const currentExists = typeof current !== "undefined" && current !== null;
  const previousExists = typeof previous !== "undefined" && previous !== null;
  if (!currentExists || !previousExists) return;

  // const animationScale = level === "primary" ? "md" : "sm";
  const animationScale: {
    primary: AnimationScale;
    secondary: AnimationScale;
    overlay: AnimationScale;
  } = {
    primary: "md",
    secondary: "sm",
    overlay: "lg",
  };

  const isForward = current > previous;
  const isBackward = current < previous;
  const isCurrentOverlay = stepsType?.[current] === STEP_TYPES.DIALOG;
  const isPreviousOverlay = stepsType?.[previous] === STEP_TYPES.DIALOG;
  const isForwardOverlayOnScreen =
    isForward && isCurrentOverlay && !isPreviousOverlay;
  const isBackwardOverlayOnScreen =
    isBackward && isCurrentOverlay && !isPreviousOverlay;
  // find the maximum index of stepsType which is 'screen' and index is minor than current
  const screenUnderOverlay = stepsType
    ?.slice(0, current)
    .reduce((maxIndex, step, index) => {
      return step === "screen" ? index : maxIndex;
    }, -1);

  // TODO: if both current and previous are overlays, find the last screen and save it in a variable to animate
  // or if current is screen and last is overlay ? -> last screen must be removed
  const isScreenAfterOverlay =
    isForward && !isCurrentOverlay && isPreviousOverlay;
  /** Iterate over each group, and animate elements in the current and previous groups only* */
  Object.entries(motionGroups)?.forEach(([groupKey, groupElements]) => {
    if (groupKey === current.toString()) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "in",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale[level],
          stepType: stepsType?.[current],
        });
      });
    }
    if (
      isBackwardOverlayOnScreen &&
      groupKey === screenUnderOverlay?.toString()
    ) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "in",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale[level],
          stepType: stepsType?.[screenUnderOverlay],
        });
      });
    }
    if (
      previousExists &&
      current !== previous &&
      groupKey === previous.toString() &&
      !isForwardOverlayOnScreen
    ) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "out",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale[level],
          stepType: stepsType?.[previous],
        });
      });
    }
    if (
      isScreenAfterOverlay &&
      current !== previous &&
      groupKey === screenUnderOverlay?.toString()
    ) {
      groupElements?.forEach((group) => {
        animateElementsInGroup({
          elementAnimationDirection: "out",
          animationDirection,
          elementMotionAttr,
          group,
          orientation,
          scale: animationScale[level],
          stepType: stepsType?.[screenUnderOverlay],
        });
      });
    }
    // if (level === "overlay") {
    //   const overlayBgMotionAttr = "primary";

    //   groupElements?.forEach((group) => {
    //     animateElementsInGroup({
    //       elementAnimationDirection, //: "none",
    //       animationDirection, //: "none",
    //       elementMotionAttr: overlayBgMotionAttr,
    //       group,
    //       orientation,
    //       scale: animationScale,
    //     });
    //   });
    // }
  });
};
